import flow from 'lodash/flow';
import values from 'lodash/values';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import browser from '../../helpers/browser';
import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';
import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import getStateValue from '../../helpers/getStateValue';
import { sendMessage } from '../TemplatePreview/utils';
import getElementsOrder from './utils/getElementsOrder';
import checkAllElementsInBurger from './utils/checkAllElementsInBurger';
import checkIsOnlyCollapsed from './utils/checkIsOnlyCollapsed';
import checkIsLogoBeforeCart from './utils/checkIsLogoBeforeCart';
import clearElementAlignment from './utils/clearElementAlignment';
import removeSloganFromBurgerElements from './utils/removeSloganFromBurgerElements';

import {
  ACTIVE_CLASS,
  BURGER,
  BURGER_BOX,
  BURGER_MENU_OPENED,
  CART_CLASS,
  COLLAPSED_CLASS,
  HEADER_CLASS,
  HEADER_LAYOUT_WRAPPER,
  HEADER_WRAPPER,
  HIDE_SCROLL_BODY_CLASS,
  LOGO_CLASS,
  MENU_WITH_CHILDREN,
  NAV,
  NAV_BOX_WRAP,
  NAV_BOX_STICKY,
  SUB_MENU_BTN_CLASS, SUB_MENU_ITEM,
  SUB_MENU_LIST, SUB_MENU_NAV_HAS_SCROLL,
  SUB_MENU_OPEN_CLASS, SUB_MENU_SCROLL,
  WITH_BURGER_CLASS,
  WITHOUT_BURGER_CLASS,
  HEADER_NAV,
  MAIN_HEADER,
  TOPBAR,
  SUBHEADER,
  SLOGAN_COLLAPSED,
  HEADER_TYPES,
  HEADER_ELEMENT_WRAPPER,
  FIXED,
  TOP_POSITION_CLASS,
  FIXED_TEMPLATE,
  SOCIAL_SELECTOR,
  FIRST_SECTION_BG_SELECTOR,
  FIRST_VIDEO_BG_SELECTOR,
  FIRST_MAP_BG_SELECTOR,
  LEFT,
  RIGHT,
  CENTER,
} from './constants';

import sortBurgerElements from './utils/sortBurgerElements';
import getHeaders from './utils/getHeaders';
import shouldUncollapse from './utils/shouldUncollapse';
import checkIsMobile from './utils/checkIsMobile';
import CartButtonWidget from '../CartButton/CartButton';
import Header from './Header';

const isTemplate = getStateValue('isTemplate', false);

class HeaderManager extends WidgetWrapper {
  init = () => {
    this.elHeaderWrapper = dom.getElement(this.selector);

    if (!this.elHeaderWrapper) return;

    this.elHeader = dom.getElement(HEADER_CLASS, this.elHeaderWrapper);

    if (!this.elHeader) return;

    this.settings = JSON.parse(this.elHeaderWrapper.dataset.settings);
    this.offsets = JSON.parse(this.elHeaderWrapper.dataset.offset);
    this.elMainHeader = dom.getElement(MAIN_HEADER, this.elHeader);
    this.elTopbar = dom.getElement(TOPBAR, this.elHeader);
    this.elSubheader = dom.getElement(SUBHEADER, this.elHeader);

    this.headers = getHeaders(this.elMainHeader, this.elTopbar, this.elSubheader);

    this.initCloneWithoutBurger();
    this.initBurger();
    this.initSubMenuScroll();
    this.initHeaders();
    this.initFixed();

    dom.showOpacity(this.elHeaderWrapper);
  }

  initCloneWithoutBurger = () => {
    const { burger } = this.settings;

    if (burger) return;

    values(this.headers).forEach(({ type, element }) => {
      this.headers[type].clone = dom.getElement(
        HEADER_WRAPPER,
        element,
      ).cloneNode(true);
    });
  }

  initBurger = () => {
    this.elBurger = dom.getElement(BURGER, this.elHeader);
    this.elNav = dom.getElement(NAV, this.elHeader);
    this.elBurgerBox = dom.getElement(BURGER_BOX, this.elHeader);

    if (this.elBurger) this.connectControlBurgerMenu(this.elBurger, this.elNav);
  }

  fixSocialsToBottom = () => {
    const elStickyWrapper = dom.getElement(NAV_BOX_STICKY, this.elHeader);
    const elSocials = dom.getElement(SOCIAL_SELECTOR, this.elHeader);

    if (!elSocials) return;

    const elFixedWrapper = dom.createElement('div');
    dom.addClass(elFixedWrapper, 's-wrapper_nav');
    dom.addClass(elFixedWrapper, 's-wrapper');
    dom.addClass(elFixedWrapper, 'nav__box-fixed');
    elFixedWrapper.appendChild(elSocials.parentNode);

    elStickyWrapper.appendChild(elFixedWrapper);
  }

  initHeaders = () => {
    values(this.headers)
      .forEach(({ type, element }) => {
        const header = new Header(element, type, this);
        header.init();
      });
  }

  initFixed = () => {
    this.elHeaderWrapperFixed = dom.getElement(FIXED, this.elHeaderWrapper);

    if (!this.elHeaderWrapperFixed) return;

    this.isFixed = true;
    this.changeTemplateOffset();

    dom.on(dom.window, 'scroll', () => {
      if (dom.window.pageYOffset !== 0) {
        dom.window.requestAnimationFrame(() => {
          dom.removeClass(this.elHeaderWrapper, TOP_POSITION_CLASS);
        });
      } else {
        dom.window.requestAnimationFrame(() => {
          dom.addClass(this.elHeaderWrapper, TOP_POSITION_CLASS);
        });
      }
    });

    subscribeToDeviceChanging(this.elHeader, this.changeTemplateOffset);
  }

  collapse = (type) => {
    this.headers[type].collapsed = true;
    this.isCollapsed = true;

    dom.hide(this.elTopbar);
    dom.hide(this.elSubheader);

    this.toggleCollapsedClass();
    this.toggleBurgerClass();
    this.toggleSloganVisibility();

    if (this.elCloneWithBurger) {
      this.replaceMainHeaderWithBurger();
    } else {
      this.setCloneWithBurger();
    }

    if (this.isFixed) this.changeTemplateOffset();
  };

  uncollapse = (type) => {
    this.headers[type].collapsed = false;

    if (!shouldUncollapse(this.headers)) return;

    this.isCollapsed = false;

    this.toggleCollapsedClass();
    this.toggleBurgerClass();
    this.toggleSloganVisibility();
    this.replaceBurgerWithHeadersClone();
    this.updateCartButton();

    if (this.isFixed) this.changeTemplateOffset();
  }

  toggleCollapsedClass = () => {
    if (this.isCollapsed) {
      dom.addClass(this.elHeader, COLLAPSED_CLASS);
    } else {
      dom.removeClass(this.elHeader, COLLAPSED_CLASS);
    }
  }

  toggleBurgerClass = () => {
    const elWrapper = dom.getElement(HEADER_ELEMENT_WRAPPER, this.elMainHeader);

    if (!elWrapper) return;

    dom.removeClass(elWrapper, this.isCollapsed ? WITHOUT_BURGER_CLASS : WITH_BURGER_CLASS);
    dom.addClass(elWrapper, this.isCollapsed ? WITH_BURGER_CLASS : WITHOUT_BURGER_CLASS);
  }

  toggleSloganVisibility = () => {
    const elSloganCollapsed = dom.getElement(SLOGAN_COLLAPSED);

    if (!elSloganCollapsed) return;

    if (this.isCollapsed) {
      dom.show(elSloganCollapsed);
      dom.addClass(elSloganCollapsed, `_${CENTER}`);
    } else {
      dom.hide(elSloganCollapsed);
    }
  }

  changeTemplateOffset = (device) => {
    const elTemplate = dom.getElement(FIXED_TEMPLATE);

    if (!elTemplate) return;

    if (this.isCollapsed) {
      const {
        desktop, mobile, topOffset, bottomOffset,
      } = this.offsets[HEADER_TYPES.MAIN];
      const collapsedOffset = checkIsMobile(device)
        ? mobile + topOffset + bottomOffset
        : desktop + topOffset + bottomOffset;

      dom.updateStyle(elTemplate,
        { paddingTop: `${collapsedOffset}px` });

      this.changeBackgroundOffset(collapsedOffset);

      return;
    }

    const offset = checkIsMobile(device)
      ? this.offsets.total.mobile
      : this.offsets.total.desktop;

    dom.updateStyle(elTemplate,
      {
        paddingTop: checkIsMobile(device)
          ? `${offset}px`
          : `${offset}px`,
      });

    this.changeBackgroundOffset(offset);
  }

  changeBackgroundOffset = (offset) => {
    const elFirstSection = dom.getElement('.s-section');

    if (!elFirstSection) return;

    const isSliderBackground = dom.hasClass(elFirstSection, 's-section__slider-bg');

    if (isSliderBackground) {
      const elSliderSection = dom.getElement('.slider__section', elFirstSection);

      dom.updateStyle(elSliderSection, {
        paddingTop: `${offset}px`,
        marginTop: `-${offset}px`,
      });

      return;
    }

    this.setFixedPlugOffset(elFirstSection, offset);

    const elSectionBg = dom.getElement(FIRST_SECTION_BG_SELECTOR)
      || dom.getElement(FIRST_VIDEO_BG_SELECTOR)
      || dom.getElement(FIRST_MAP_BG_SELECTOR);

    dom.updateStyle(elSectionBg, {
      top: `-${offset}px`,
      height: `calc(100% + ${offset}px)`,
    });
  }

  setFixedPlugOffset = (elFirstSection, offset) => {
    const elFixedPlugExisting = dom.getElement('.fixed-header-plug', elFirstSection);
    const styleData = elFirstSection.dataset?.style;

    if (!styleData) return;

    if (elFixedPlugExisting) {
      dom.updateStyle(elFixedPlugExisting, {
        height: `${offset}px`,
        top: `-${offset}px`,
      });

      return;
    }

    const elFixedPlug = dom.createElement('div');
    const { background } = JSON.parse(styleData);

    dom.addClass(elFixedPlug, 'fixed-header-plug');
    dom.updateStyle(elFixedPlug, {
      transform: 'translateY(0px)',
      height: `${offset}px`,
      top: `-${offset}px`,
      background,
    });

    if (!browser.isIe()) elFirstSection.firstChild.prepend(elFixedPlug);
    else elFirstSection.firstChild.appendChild(elFixedPlug);
  }

  replaceBurgerWithHeadersClone = () => {
    values(this.headers).forEach(({ type, element, clone }) => {
      const elHeaderElementWrapper = dom.getElement(HEADER_ELEMENT_WRAPPER, element);
      const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, element);

      elHeaderElementWrapper.replaceChild(clone, elHeaderWrapper);

      if (type !== HEADER_TYPES.MAIN) dom.show(element);
    });
  }

  replaceMainHeaderWithBurger = () => {
    const elHeaderElementWrapper = dom.getElement(HEADER_ELEMENT_WRAPPER, this.elMainHeader);
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elMainHeader);

    if (!elHeaderElementWrapper) return;

    elHeaderElementWrapper.replaceChild(this.elCloneWithBurger, elHeaderWrapper);
    this.elBurger = dom.getElement(BURGER, this.elHeader);
    this.elBurgerBox = dom.getElement(BURGER_BOX, this.elHeader);
    this.elNav = dom.getElement(NAV, this.elHeader);
    this.connectControlBurgerMenu(this.elBurger, this.elNav);
    this.updateCartButton();
  }

  updateCartButton = () => {
    const elHeaderCart = dom.getElement(`.${CART_CLASS}`, this.elHeader);

    if (elHeaderCart) CartButtonWidget.refreshCartButtonWidget(this.elHeader);
  };

  initSubMenuScroll = () => {
    const { burger } = this.settings;

    const removeScroll = (scrollWrapper) => {
      dom.updateStyle(scrollWrapper, {
        overflowY: null,
        width: null,
        height: null,
      });
    };

    let currentScrollWrapper = null;
    const resetScroll = () => {
      if (!currentScrollWrapper) return;

      currentScrollWrapper.scrollTop = 0;
    };

    const init = () => {
      const subMenus = dom.getCollection(SUB_MENU_LIST, this.elHeader);

      if (!subMenus.length) return;

      subMenus.forEach((subMenuWrapper) => {
        const scrollWrapper = dom.getElement(SUB_MENU_SCROLL, subMenuWrapper);

        if (!scrollWrapper) return;

        currentScrollWrapper = scrollWrapper;

        dom.updateStyle(subMenuWrapper, { height: null });
        const parent = subMenuWrapper.closest(MENU_WITH_CHILDREN);
        dom.off(parent, 'mouseleave', resetScroll);

        if (this.isCollapsed || burger) {
          if (dom.hasClass(parent, SUB_MENU_OPEN_CLASS)) {
            dom.removeClass(parent, SUB_MENU_OPEN_CLASS);
          }

          dom.removeClass(subMenuWrapper, SUB_MENU_NAV_HAS_SCROLL);
          removeScroll(scrollWrapper);
          return;
        }

        dom.on(parent, 'mouseleave', resetScroll);

        const bottomOffset = 20 + (browser.isIe() ? 20 : 0);
        const { height } = scrollWrapper.getBoundingClientRect();
        const { top } = subMenuWrapper.getBoundingClientRect();

        if (top + height + bottomOffset <= dom.window.innerHeight) {
          dom.removeClass(subMenuWrapper, SUB_MENU_NAV_HAS_SCROLL);
          removeScroll(scrollWrapper);
          return;
        }

        dom.updateStyle(scrollWrapper, {
          overflowY: 'scroll',
          width: '100%',
          height: '100%',
        });

        const subMenuHeight = dom.window.innerHeight - top - bottomOffset;
        dom.addClass(subMenuWrapper, SUB_MENU_NAV_HAS_SCROLL);
        dom.updateStyle(subMenuWrapper, { height: `${subMenuHeight}px` });
      });
    };
    init();

    subscribeToDebouncedResizeChanging(dom.document.body, init, 300);
  };

  connectControlBurgerMenu = (burger, nav) => {
    if (burger) dom.on(burger, 'click', this.toggleBurgerMenu);
    if (nav) dom.on(nav, 'click', this.toggleBurgerSubMenu);
  };

  toggleBurgerMenu = () => {
    if (dom.hasClass(this.elBurgerBox, ACTIVE_CLASS)) {
      dom.removeClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);
      dom.removeClass(dom.document.body, BURGER_MENU_OPENED);

      if (isTemplate) sendMessage({ type: 'burgerHeight', burgerOpen: false });
    } else {
      dom.addClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);
      dom.addClass(dom.document.body, BURGER_MENU_OPENED);

      if (isTemplate) sendMessage({ type: 'burgerHeight', burgerOpen: true });
    }

    dom.toggleClass(this.elBurgerBox, ACTIVE_CLASS);
  };

  toggleBurgerSubMenu = (e) => {
    const { target } = e;

    if (!dom.hasClass(target, SUB_MENU_BTN_CLASS)) return;

    e.preventDefault();
    const navItem = target.parentElement.parentElement;
    const elSubMenu = dom.getElement(SUB_MENU_LIST, navItem);
    const isOpen = dom.hasClass(navItem, SUB_MENU_OPEN_CLASS);

    if (isOpen) {
      dom.updateStyle(elSubMenu, { height: '0px' });
    } else {
      const elSubMenuItems = dom.getCollection(SUB_MENU_ITEM, elSubMenu);
      const updatedHeight = elSubMenuItems.length * (elSubMenuItems[0].clientHeight + 4);
      dom.updateStyle(elSubMenu, { height: `${updatedHeight}px` });
    }

    dom.toggleClass(navItem, SUB_MENU_OPEN_CLASS);
  };

  setCloneWithBurger = () => {
    const elBurgerBox = dom.getElement(BURGER_BOX);
    const elHeaderNav = dom.getElement(HEADER_NAV, this.elHeader);

    this.elementsOrder = getElementsOrder(this.elMainHeader);
    this.isLogoBeforeCart = checkIsLogoBeforeCart(this.elMainHeader);
    this.isOnlyCollapsed = checkIsOnlyCollapsed(this.elMainHeader);

    const sortedBurgerElements = flow(
      () => dom.getCollection(`${HEADER_LAYOUT_WRAPPER}:not(.${LOGO_CLASS})`, this.elHeader),
      sortBurgerElements,
      removeSloganFromBurgerElements,
    )();

    elBurgerBox.removeAttribute('style');

    if (elHeaderNav) this.collapseMenu();

    this.orderCollapsedElements();
    this.alignCollapsedElements();

    const elLayoutsWrapper = dom.getElement(NAV_BOX_WRAP, elBurgerBox);

    sortedBurgerElements.forEach((el) => {
      if (!dom.hasClass(el, CART_CLASS)) elLayoutsWrapper.appendChild(el);
    });

    this.fixSocialsToBottom();
    this.elCloneWithBurger = dom.getElement(HEADER_WRAPPER, this.elMainHeader).cloneNode(true);
  };

  collapseMenu = () => {
    const elHeaderNav = dom.getElement(HEADER_NAV, this.elHeader);
    const elSubNav = dom.getCollection('.nav__item_has-child', elHeaderNav);
    const elForRemoveStyle = dom.getCollection('.nav__item .nav__item-inner, .nav__item .nav__link', elHeaderNav);

    elForRemoveStyle.forEach((el) => {
      el.removeAttribute('style');
    });

    if (elSubNav) {
      const elButton = dom.createElement('button');
      elButton.type = 'button';
      elButton.tabIndex = -1;
      dom.addClass(elButton, 'btn');
      dom.addClass(elButton, 'sub-menu__toggle');

      elSubNav.forEach((el) => {
        const button = elButton.cloneNode(true);
        const elChild = dom.getElement('.nav__item-inner', el);
        elChild.appendChild(button);
      });

      const elForRemoveSubmenuStyle = dom.getCollection('.sub-menu__link', elHeaderNav);

      elForRemoveSubmenuStyle.forEach((el) => {
        el.removeAttribute('style');
      });
    }

    dom.removeClass(elHeaderNav, [`_${RIGHT}`, `_${LEFT}`, `_${CENTER}`]);
  }

  orderCollapsedElements = () => {
    const elBurgerBox = dom.getElement(BURGER_BOX);
    const elHeaderCart = dom.getElement(`.${CART_CLASS}`, this.elHeader);
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elMainHeader);
    const elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, this.elHeader);
    const isLogoExist = !!elHeaderLogo;
    const isCartExist = !!elHeaderCart;

    const {
      isCartFirst,
      isLogoCenter,
      isLogoLast,
      isCartLast,
      isSingle,
    } = this.elementsOrder;

    if (this.isOnlyCollapsed) {
      if (isCartFirst) {
        elHeaderWrapper.insertBefore(elHeaderLogo, elBurgerBox);
      }

      return;
    }

    if (elHeaderLogo && isLogoLast) {
      elHeaderWrapper.insertBefore(elBurgerBox, elHeaderLogo);
      return;
    }

    if (!isLogoExist && isCartExist && (isCartLast || isSingle)) {
      elHeaderWrapper.insertBefore(elBurgerBox, elHeaderCart);
      return;
    }

    if (!isLogoExist || !isCartExist) return;

    if (isLogoCenter) {
      const elBefore = this.isLogoBeforeCart ? elBurgerBox : elHeaderLogo;
      const elAfter = this.isLogoBeforeCart ? elHeaderLogo : elBurgerBox;

      elHeaderWrapper.insertBefore(elBefore, elAfter);

      return;
    }

    if (isCartLast || (!isCartFirst && !this.isLogoBeforeCart)) {
      elHeaderWrapper.insertBefore(elBurgerBox, elHeaderCart);
    } else {
      elHeaderWrapper.insertBefore(elHeaderCart, elBurgerBox);
    }
  }

  alignCollapsedElements = () => {
    const elBurgerBox = dom.getElement(BURGER_BOX);
    const elHeaderCart = dom.getElement(`.${CART_CLASS}`, this.elHeader);
    const elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, this.elHeader);
    const isLogoExist = !!elHeaderLogo;
    const isCartExist = !!elHeaderCart;
    const isAllCollapsed = checkAllElementsInBurger(this.elMainHeader);

    const {
      isLogoFirst,
      isCartFirst,
      isLogoCenter,
      isCartCenter,
      isLogoLast,
      isCartLast,
      isSingle,
    } = this.elementsOrder;

    elBurgerBox.removeAttribute('style');
    clearElementAlignment(elBurgerBox);
    clearElementAlignment(elHeaderLogo);

    if (isAllCollapsed) {
      dom.addClass(elBurgerBox, `_${RIGHT}`);
      return;
    }

    if (isLogoExist && !isCartExist) {
      if (isLogoFirst || isLogoCenter || isSingle) {
        dom.addClass(elBurgerBox, `_${RIGHT}`);
      }

      if (isLogoLast) {
        dom.addClass(elBurgerBox, `_${LEFT}`);
      }
    }

    if (isCartExist && !isLogoExist) {
      if (isCartFirst || isCartCenter || isSingle) {
        dom.addClass(elBurgerBox, `_${RIGHT}`);
      }

      if (isCartLast) {
        dom.addClass(elBurgerBox, `_${LEFT}`);
      }
    }

    if (isLogoExist && isCartExist) {
      if (this.isOnlyCollapsed && isCartFirst) {
        dom.addClass(elHeaderLogo, `_${CENTER}`);
        dom.addClass(elBurgerBox, RIGHT);

        return;
      }

      if (isLogoFirst) {
        dom.addClass(elHeaderLogo, `_${LEFT}`);
        dom.addClass(elBurgerBox, RIGHT);
      }

      if (isLogoLast) {
        dom.addClass(elHeaderLogo, `_${RIGHT}`);
        dom.addClass(elBurgerBox, LEFT);
      }

      if (isLogoCenter) {
        dom.addClass(elHeaderLogo, `_${CENTER}`);

        if (this.isLogoBeforeCart) {
          dom.addClass(elBurgerBox, LEFT);
        } else {
          dom.addClass(elBurgerBox, RIGHT);
        }
      }
    }
  }
}

export default HeaderManager;
